import React, { forwardRef } from "react"
import styled from "styled-components"

import { slugify } from "../lib/utils"

const FeesSection = forwardRef(({ sectionRef, feesData, navId }) => {
  return (
    <FeesContainerStyling ref={sectionRef} id={slugify(navId)}>
      <h2>{feesData.sectionTitle}</h2>

      <BenefitRowStyling>
        {feesData.feeItem.map((feeItem, feeIndex) => {
          return (
            <BenefitItemStyling key={feeIndex}>
              <img src={feeItem.icon.localFile.publicURL} alt={feeItem.title} />
              <div>
                <h4>{feeItem.title}</h4>
                {feeItem.description && <p>{feeItem.description}</p>}
              </div>
            </BenefitItemStyling>
          )
        })}
      </BenefitRowStyling>

      <DisclaimerRowStyling>
        <h4>{feesData.note}</h4>
        <p>{feesData.disclaimerDescription}</p>
      </DisclaimerRowStyling>
    </FeesContainerStyling>
  )
})

export default FeesSection

// ===============
//      STYLES
// ===============

const FeesContainerStyling = styled.div`
  margin: 100px auto 0 auto;
  width: 100%;
  padding: 100px 0 0 0;
  background: ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.white};

  & h2 {
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
  }
`

const BenefitRowStyling = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px 0 100px 0;

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;

    h4 {
      font-size: 120%;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    flex-direction: column;
  }
`

const DisclaimerRowStyling = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colors.yellow};
  padding: 50px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;

    h4 {
      font-size: 120%;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    text-align: center;
  }
`

const BenefitItemStyling = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 300px;
  flex-basis: 0;
  align-items: start;
  justify-content: left;
  margin: 0 50px;

  img {
    margin-top: 10px;
    height: fit-content;
  }

  h4,
  p {
    margin: 2px;
    color: inherit;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-bottom: 30px;
  }
`
