import React from "react"
import styled from "styled-components"

import { scrollToY } from "../lib/utils"

const BannerSection = ({
  bookingRef,
  setScrollPosition = event => {},
  bannerData,
}) => {
  const handleClick = (event, ref) => {
    event?.preventDefault()
    scrollToY(ref?.current?.offsetTop - 120)
    setScrollPosition(ref?.current?.offsetTop - 120)
  }

  return (
    <BannerContainerStyling>
      <h1>{bannerData.title}</h1>
      <p>{bannerData.subtitle}</p>
      <div className="overlay"></div>
      <img
        src={bannerData.image.localFile.publicURL}
        alt={bannerData.image.alternativeText}
      />

      <StyledBookingButton
        className="styled-button"
        onClick={e => handleClick(e, bookingRef)}
      >
        <a href="/">{bannerData.buttonText}</a>
      </StyledBookingButton>
    </BannerContainerStyling>
  )
}

export default BannerSection

// ===============
//      STYLES
// ===============

const BannerContainerStyling = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;

  h1,
  p {
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  }

  .overlay {
    position: absolute;
    z-index: -4;
    height: 100%;
    width: 100%;
    background: rgba(23, 30, 99, 0.3);
    mix-blend-mode: multiply;
  }

  img {
    position: absolute;
    z-index: -5;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    height: 500px;
  }
`

const StyledBookingButton = styled.button`
  color: ${({ theme }) => theme.colors.navy};
  height: 50px;
  border-radius: 5px;
  border: none;
  background: white;
  min-width: 50px;
  max-width: 300px;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  margin-top: 50px;

  &:hover {
    background: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.navy};
  }
  a {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

// ===============
//      QUERY
// ===============
