import React from "react"

const LeftblocksIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="105"
    height="108"
    viewBox="0 0 105 108"
  >
    <g data-name="Group 676" transform="translate(-100 -2951.464)">
      <rect
        width="42"
        height="42"
        fill="#ffcb55"
        data-name="Rectangle 881"
        rx="2"
        transform="translate(100 3005.464)"
      ></rect>
      <path
        fill="#00c0d9"
        d="M18.751 3.158a2 2 0 013.5 0l17.104 30.873A2 2 0 0137.605 37H3.395a2 2 0 01-1.749-2.969z"
        data-name="Polygon 1"
        transform="translate(142 2951.464)"
      ></path>
      <ellipse
        cx="21.5"
        cy="21"
        fill="#fc4963"
        data-name="Ellipse 78"
        rx="21.5"
        ry="21"
        transform="translate(162 3017.464)"
      ></ellipse>
    </g>
  </svg>
)

export default LeftblocksIcon
