import React, { forwardRef } from "react"
import styled from "styled-components"

import ContactForm from "./contact-form/contact-form"

const BookingComponent = forwardRef(({ sectionRef, bookingData }) => {
  const heading = false

  const bookingForm = bookingData.bookingForm.map(fieldData => {
    return fieldData
  })

  return (
    <BookingContainerStyling ref={sectionRef}>
      <h2>{bookingData.sectionTitle}</h2>
      <h5 className="sub-title">{bookingData.sectionSubTitle}</h5>

      <ContactForm
        fields={bookingForm[0].field}
        heading={heading}
        buttonText={bookingForm[0].buttonText}
        formName="booking"
      />
    </BookingContainerStyling>
  )
})

export default BookingComponent

// ===============
//      STYLES
// ===============

const BookingContainerStyling = styled.div`
  margin: 100px auto;
  width: ${({ theme }) => theme.breakLarge};

  .sub-title {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 400;
    text-align: center;
  }

  & button {
    align-self: center;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`
