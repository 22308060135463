import React from "react"
import styled from "styled-components"

const MainBenefitRow = ({ benefitData }) => {

  return (
    <BenefitRowStyling>
      {benefitData?.benefit.map((valueItem, valueIndex) => {
        return (
          <BenefitItemStyling key={valueIndex}>
            <h4>{valueItem.benefitTitle}</h4>
            <p>{valueItem.benefitDescription}</p>
          </BenefitItemStyling>
        )
      })}
    </BenefitRowStyling>
  )
}

export default MainBenefitRow

// ===============
//      STYLES
// ===============

const BenefitRowStyling = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ theme }) => theme.breakLarge};
  margin: -30px auto 0 auto;

  & div:first-child {
    border-radius: 10px 0 0 10px;
  }

  & div:last-child {
    border-radius: 0 10px 10px 0;
  }

  & div:nth-child(1) {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }

  & div:nth-child(2) {
    background: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.navy};
  }
  & div:nth-child(3) {
    background: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
  }
  & div:nth-child(4) {
    background: ${({ theme }) => theme.colors.lightblue};
    color: ${({ theme }) => theme.colors.navy};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 100%;

    h4 {
      font-size: 120%;
    }

    & div:first-child {
      border-radius: 0;
    }

    & div:last-child {
      border-radius: 0;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    flex-direction: column;
  }
`

const BenefitItemStyling = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  text-align: left;
  align-items: flex-start;
  padding: 25px 30px;

  h4,
  p {
    margin: 2px;
    color: inherit;
  }
`

// ===============
//      QUERY
// ===============
