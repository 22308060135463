import React from "react"
import styled from "styled-components"

import ImageGallery from "react-image-gallery"

import "react-image-gallery/styles/css/image-gallery.css"

const Gallery = ({ images }) => {
  const imageSet = images.map((image) => ({
    original: image.localFile.childImageSharp.original.src,
    thumbnail: image.localFile.childImageSharp.original.src,
  }))
  return (
    <StyledGallery>
    
      <ImageGallery
        items={imageSet}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        useTranslate3D={false}
      />
    </StyledGallery>
  )
}

export default Gallery

// ===============
//     STYLES
// ===============

const StyledGallery = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  max-height: 600px;

  .image-gallery-content {
    position: relative;
    max-height: 600px;

    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      height: 400px;
    }

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      height: 300px;
    }
  }
  .image-gallery-slide-wrapper {
    position: unset;
    outline: none;
    max-height: 600px;

    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      height: 400px;
    }

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      height: 300px;
    }
  }

  .image-gallery-slide {
    display: flex !important;
    align-items: center;
    outline: none;
    width: 100%;
    height: 600px;
    background-color: black;

    .image-gallery-image {
      width: 100%;
      height: 600px;
      margin: 0;
      object-fit: contain;

      @media (max-width: ${({ theme }) => theme.breakMedium}) {
        height: 400px;
      }

      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        height: 300px;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      height: 400px;
    }

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      height: 300px;
    }
  }

  .image-gallery-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;

    position: absolute;
    bottom: 30px;
    right: 30px;
    border: none;
    z-index: 5;
    filter: none;
    color: ${({ theme }) => theme.colors.white};
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .image-gallery-left-nav {
    left: 30px;
  }
  .image-gallery-fullscreen-button {
    padding: 5px;
  }
  .image-gallery-icon:hover {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.8);
    color: ${({ theme }) => theme.colors.blue};
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    height: 400px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    height: 300px;
  }
`
