import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../theme/layout"
import Seo from "../theme/header/seo"
import BannerSection from "../components/banner"
import MainBenefitRow from "./main-benefits"
import AboutSection from "./about"
import OurSchoolSection from "./our-school"
import StaffSection from "./staff"
import PrincipalNoteSection from "./pnote"
import AcademicsSection from "./academics"
import BookingComponent from "./booking"
import ContactSection from "./contact-us"
import ActivitiesSection from "./activities"
import FeesSection from "./fees"
import EnrollmentSection from "./enrollment"

const LandingPageComponent = () => {
  const { headerContent, homeContent } = useStaticQuery(HOMEPAGE_QUERY)

  const nodesHeaderContent = headerContent?.nodes[0]

  const navLinks = nodesHeaderContent.navigationLinks.map(link => {
    return link.navigationLink
  })

  const nodesHomeContent = homeContent?.nodes[0]

  const feesData = nodesHomeContent.blocks[5]
  const galleryData = nodesHomeContent.blocks[2].gallery
  const schoolData = nodesHomeContent.blocks[2].ourSchool
  const schoolTitle = nodesHomeContent.blocks[2].ourSchoolSectionTitle
  const aboutData = [
    nodesHomeContent.blocks[2].companyDescription,
    nodesHomeContent.blocks[2].companyImage,
    nodesHomeContent.blocks[2].sectionTitle,
  ]
  const pnoteData = {
    title: nodesHomeContent.blocks[2].pnoteSectionTitle,
    subTitle: nodesHomeContent.blocks[2].pnoteSectionSubTitle,
    description: nodesHomeContent.blocks[2].pnoteDescription,
    image: nodesHomeContent.blocks[2].pnoteImage?.localFile.publicURL,
  }

  const bannerData = nodesHomeContent.blocks[0]
  const benefitData = nodesHomeContent.blocks[1]
  const enrollData = nodesHomeContent.blocks[6]
  const academicsData = nodesHomeContent.blocks[3]

  const activityData = nodesHomeContent.blocks[4]

  const bookingData = nodesHomeContent.blocks[8]
  const contactData = nodesHomeContent.blocks[7]

  const staffData = nodesHomeContent.blocks[9]

  const aboutRef = useRef(null)
  const curriculumRef = useRef(null)
  const feesRef = useRef(null)
  const staffRef = useRef(null)
  const enrolRef = useRef(null)
  const contactRef = useRef(null)
  const bookingRef = useRef(null)

  return (
    <Layout
      aboutRef={aboutRef}
      curriculumRef={curriculumRef}
      feesRef={feesRef}
      staffRef={staffRef}
      enrolRef={enrolRef}
      contactRef={contactRef}
      headerContent={nodesHeaderContent}
      navLinks={navLinks}
    >
      <Seo title="Home" />
      <BannerSection bookingRef={bookingRef} bannerData={bannerData} />
      <MainBenefitRow benefitData={benefitData} />
      <AboutSection
        sectionRef={aboutRef}
        navId={navLinks[0]}
        aboutData={aboutData}
      />
      <OurSchoolSection
        galleryData={galleryData}
        schoolData={schoolData}
        schoolTitle={schoolTitle}
      />
      <AcademicsSection
        sectionRef={curriculumRef}
        navId={navLinks[1]}
        academicsData={academicsData}
      />
      <PrincipalNoteSection pnoteData={pnoteData} />
      <ActivitiesSection activityData={activityData} />
      <StaffSection
        sectionRef={staffRef}
        navId={navLinks[2]}
        staffData={staffData}
      />
      <FeesSection
        sectionRef={feesRef}
        feesData={feesData}
        navId={navLinks[3]}
      />
      <EnrollmentSection
        sectionRef={enrolRef}
        navId={navLinks[4]}
        enrollData={enrollData}
      />
      <ContactSection
        sectionRef={contactRef}
        navId={navLinks[5]}
        contactData={contactData}
      />
      <BookingComponent sectionRef={bookingRef} bookingData={bookingData} />
    </Layout>
  )
}

export default LandingPageComponent

// ===============
//      STYLES
// ===============

// ===============
//      QUERY
// ===============

const HOMEPAGE_QUERY = graphql`
  {
    headerContent: allStrapiHeader {
      nodes {
        siteLogo {
          localFile {
            publicURL
          }
        }
        navigationLinks {
          navigationLink
        }
      }
    }

    homeContent: allStrapiPage(filter: { title: { eq: "Homepage" } }) {
      nodes {
        blocks {
          ... on STRAPI__COMPONENT_BLOCKS_ABOUT_US {
            companyDescription
            galleryTitle
            ourSchoolSectionTitle
            pnoteDescription
            pnoteSectionSubTitle
            pnoteSectionTitle
            sectionTitle
            pnoteImage {
              localFile {
                publicURL
              }
            }
            ourSchool {
              description
              topic
            }
            gallery {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            companyImage {
              localFile {
                publicURL
              }
            }
          }
          ... on STRAPI__COMPONENT_BLOCKS_ACTIVITIES {
            paragraphOne
            paragraphTwo
            paragraphThree
            sectionTitle
            sectionSubTitle
            activityIconsOne {
              text
              image {
                localFile {
                  publicURL
                }
              }
            }
            activityIconsTwo {
              text
              image {
                localFile {
                  publicURL
                }
              }
            }
            activityIconsThree {
              text
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_BLOCKS_BOOKINGS {
            bookingForm {
              field {
                name
                type
              }
              buttonText
            }
            sectionSubTitle
            sectionTitle
          }
          ... on STRAPI__COMPONENT_BLOCKS_CONTACT_US {
            sectionTitle
            sectionSubTitle
            contactDetails {
              title
              detail
              icon {
                localFile {
                  publicURL
                }
              }
            }
            form {
              formHeading
              buttonText
              field {
                name
                type
              }
            }
          }
          ... on STRAPI__COMPONENT_BLOCKS_CURRICULUM {
            sectionParagraph
            sectionSubtitle
            sectionTitle
            listHeading
            paragraph
            curriculumIcons {
              text
              image {
                localFile {
                  publicURL
                }
              }
            }
            image {
              localFile {
                publicURL
              }
            }
          }
          ... on STRAPI__COMPONENT_BLOCKS_ENROLLMENT {
            buttonText
            sectionSubTitle
            sectionTitle
            download {
              localFile {
                publicURL
              }
            }
          }
          ... on STRAPI__COMPONENT_BLOCKS_FEES {
            disclaimerDescription
            feeItem {
              title
              description
              icon {
                localFile {
                  publicURL
                }
              }
            }
            note
            sectionTitle
          }
          ... on STRAPI__COMPONENT_BLOCKS_HERO {
            buttonText
            subtitle
            title
            image {
              alternativeText
              localFile {
                publicURL
              }
            }
          }
          ... on STRAPI__COMPONENT_BLOCKS_OUR_STAFF {
            leadershipheading
            sectionSubTitle
            sectionTitle
            supportHeading
            teacherHeading
            staffLeadership {
              name
              title
              image {
                localFile {
                  publicURL
                }
              }
            }
            staffSupport {
              name
              title
              image {
                localFile {
                  publicURL
                }
              }
            }
            staffTeachers {
              name
              isAssistant
              title
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_BLOCKS_VALUES {
            benefit {
              benefitDescription
              benefitTitle
            }
          }
        }
        title
      }
    }
  }
`
