import React from "react"
import styled from "styled-components"

import Gallery from "./reusable-components/gallery"

const OurSchoolSection = ({ galleryData, schoolData, schoolTitle }) => {

  return (
    <OurSchoolSectionStyling>
      <h2>{schoolTitle}</h2>

      <TopicContainerStyling>
        {schoolData.map((schoolItem, schoolIndex) => {
          return (
            <TopicColumnStyling key={schoolIndex}>
              <h4>{schoolItem.topic}</h4>
              <p>{schoolItem.description}</p>
            </TopicColumnStyling>
          )
        })}
      </TopicContainerStyling>

      <Gallery images={galleryData} />
    </OurSchoolSectionStyling>
  )
}

export default OurSchoolSection

// ===============
//      STYLES
// ===============

const OurSchoolSectionStyling = styled.div`
  width: ${({ theme }) => theme.breakLarge};
  margin: 100px auto 0 auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
  }
`

const TopicContainerStyling = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 50px auto;

  div:nth-child(1) h4 {
    color: ${({ theme }) => theme.colors.blue}
  }
   div:nth-child(2) h4 {
    color: ${({ theme }) => theme.colors.yellow}
  }
   div:nth-child(3) h4 {
    color: ${({ theme }) => theme.colors.red}
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    height: auto;
  }
`

const TopicColumnStyling = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 10px;

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 20px;

  }
`

// ===============
//      QUERY
// ===============
