import React, { forwardRef } from "react"
import styled from "styled-components"

import TeacherCard from "./reusable-components/teacher-card"
import { slugify } from "../lib/utils"

const StaffSection = forwardRef(({ sectionRef, navId, staffData }) => {
  const leaderStaffContent = staffData.staffLeadership
  const teacherStaffContent = staffData.staffTeachers
  const supportStaffContent = staffData.staffSupport

  const teachers = teacherStaffContent.filter(teacherItem => {
    if (teacherItem.isAssistant === false) {
      return teacherItem
    }
  })

  const assistants = teacherStaffContent.filter(teacherItem => {
    if (teacherItem.isAssistant === true) {
      return teacherItem
    }
  })

  return (
    <StaffSectionStyling ref={sectionRef} id={slugify(navId)}>
      <h2>{staffData.sectionTitle}</h2>
      <h5 className="sub-title">{staffData.sectionSubTitle}</h5>

      <h4 className="staff-heading">{staffData.leadershipheading}</h4>

      <TeachersContainerStyling>
        {leaderStaffContent.map((staffItem, staffIndex) => {
          return (
            <TeacherCard
              key={staffIndex}
              image={staffItem.image.localFile.publicURL}
              name={staffItem.name}
              title={staffItem.title}
              alt={staffItem.name}
            />
          )
        })}
      </TeachersContainerStyling>

      <h4 className="staff-heading">{staffData.teacherHeading}</h4>

      <TeachersContainerStyling>
        {teachers.map((teacherItem, teacherIndex) => {
          return (
            <TeacherCard
              key={teacherIndex}
              name={teacherItem.name}
              image={teacherItem.image.localFile.publicURL}
              title={teacherItem.title}
              alt={teacherItem.name}
            />
          )
        })}
        {assistants.map((teacherItem, teacherIndex) => {
          return (
            <TeacherCard
              key={teacherIndex}
              name={teacherItem.name}
              image={teacherItem.image.localFile.publicURL}
              title={teacherItem.title}
              alt={teacherItem.name}
            />
          )
        })}
      </TeachersContainerStyling>

      <h4 className="staff-heading">{staffData.supportHeading}</h4>

      <TeachersContainerStyling className="support">
        {supportStaffContent.map((staffItem, staffIndex) => {
          return (
            <TeacherCard
              key={staffIndex}
              image={staffItem.image.localFile.publicURL}
              name={staffItem.name}
              title={staffItem.title}
              alt={staffItem.name}
            />
          )
        })}
      </TeachersContainerStyling>
    </StaffSectionStyling>
  )
})

export default StaffSection

// ===============
//      STYLES
// ===============

const StaffSectionStyling = styled.div`
  margin-top: 100px;
  text-align: center;

  .staff-heading {
    margin: 50px 0 0 0;
  }

  .sub-title {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
  }
`

const TeachersContainerStyling = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: ${({ theme }) => theme.breakLarge};
  margin: 50px auto;
  justify-content: center;

  &.support {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 930px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr 1fr;

    &.support {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;

    &.support {
      grid-template-columns: 1fr;
    }
  }
`

// ===============
//      QUERY
// ===============
