import React from "react"
import styled from "styled-components"

import TextIcon from "./reusable-components/text-icon"

const ActivitiesSection = ({ activityData }) => {
  return (
    <ActivitiesContainerStyling>
      <h2>{activityData.sectionTitle}</h2>
      <h5 className="sub-title">{activityData.sectionSubTitle}</h5>

      <p className="intro-p">{activityData.paragraphOne}</p>

      <ActivitiesListStyling>
        {activityData.activityIconsOne.map(
          (activitiesItem, activitiesIndex) => {
            return (
              <TextIcon
                key={activitiesIndex}
                icon={activitiesItem.image.localFile.publicURL}
                text={activitiesItem.text}
                alt={activitiesItem.text}
              />
            )
          }
        )}
      </ActivitiesListStyling>

      <p className="intro-p">{activityData.paragraphTwo}</p>

      <ActivitiesListStyling>
        {activityData.activityIconsTwo.map(
          (activitiesItem, activitiesIndex) => {
            return (
              <TextIcon
                key={activitiesIndex}
                icon={activitiesItem.image.localFile.publicURL}
                text={activitiesItem.text}
                alt={activitiesItem.text}
              />
            )
          }
        )}
      </ActivitiesListStyling>

      <p className="intro-p">{activityData.paragraphThree}</p>

      <ActivitiesListStyling>
        {activityData.activityIconsThree.map(
          (activitiesItem, activitiesIndex) => {
            return (
              <TextIcon
                key={activitiesIndex}
                icon={activitiesItem.image.localFile.publicURL}
                text={activitiesItem.text}
                alt={activitiesItem.text}
              />
            )
          }
        )}
      </ActivitiesListStyling>
    </ActivitiesContainerStyling>
  )
}

export default ActivitiesSection

// ===============
//      STYLES
// ===============

const ActivitiesContainerStyling = styled.div`
  margin: 100px auto 0 auto;
  width: ${({ theme }) => theme.breakLarge};
  text-align: center;

  .sub-title {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 400;
  }

  .intro-p {
    margin: 50px 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
  }
`

const ActivitiesListStyling = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 0;

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    flex-direction: column;

    & .text-icon {
      margin-bottom: 50px;
    }
  }
`
