import React, { forwardRef } from "react"
import styled from "styled-components"

import TextIcon from "./reusable-components/text-icon"
import { slugify } from "../lib/utils"

import LeftblocksIcon from "../images/icons/leftblocks-icon"
import RightblocksIcon from "../images/icons/rightblocks-icon"

const AcademicsSection = forwardRef(({ sectionRef, navId, academicsData }) => {
  const imageAboutContent = academicsData.image.localFile.publicURL

  return (
    <AcademicsContainerStyling ref={sectionRef} id={slugify(navId)}>
      <AcademicsInnerStyling>
        <div className="left-icon">
          <LeftblocksIcon />
        </div>
        <div className="right-icon">
          <RightblocksIcon />
        </div>
        <div className="content-widths">
          <h2>{academicsData.sectionTitle}</h2>
          <h5 className="sub-title">{academicsData.sectionSubtitle}</h5>
          <p className="intro-p">{academicsData.sectionParagraph}</p>
          <h4>{academicsData.listHeading}</h4>
          <OffersListStyling>
            {academicsData.curriculumIcons.map((offerItem, offerIndex) => {
              return (
                <TextIcon
                  key={offerIndex}
                  icon={offerItem.image.localFile.publicURL}
                  text={offerItem.text}
                  alt={offerItem.text}
                />
              )
            })}
          </OffersListStyling>
        </div>
      </AcademicsInnerStyling>
      <ContentContainerStyling>
        <img src={imageAboutContent} alt="Nicolini Nursery School" />
        <p>{academicsData.paragraph}</p>
      </ContentContainerStyling>
    </AcademicsContainerStyling>
  )
})

export default AcademicsSection

// ===============
//      STYLES
// ===============

const AcademicsContainerStyling = styled.div`
  margin: 100px auto 0 auto;
  width: 100%;
  text-align: center;

  .sub-title {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 400;
  }

  .intro-p {
    margin: 50px 0;
  }

  .content-widths {
    width: ${({ theme }) => theme.breakLarge};
    margin: 0 auto;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    height: auto;

    .content-widths {
      width: 100%;
    }
  }
`
const AcademicsInnerStyling = styled.div`
  padding: 100px 5%;
  background: ${({ theme }) => theme.colors.lightgrey};
  position: relative;

  .left-icon {
    position: absolute;
    top: 80px;
    left: 80px;
  }
  .right-icon {
    position: absolute;
    bottom: 80px;
    right: 80px;
  }

  @media only screen and (max-width: 1500px) {
    .left-icon {
      top: 50px;
      left: 50px;
    }
    .right-icon {
      bottom: 50px;
      right: 50px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    .left-icon,
    .right-icon {
      display: none;
    }
  }
`
const OffersListStyling = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 0;

  .icon-container {
    background: ${({ theme }) => theme.colors.white};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    & .text-icon {
      margin: 0 15px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    flex-direction: column;

    & .text-icon {
      margin-bottom: 50px;
    }
  }
`
const ContentContainerStyling = styled.div`
  display: flex;
  flex-direction: row;
  margin: 100px auto 0 auto;
  align-items: center;
  height: 350px;
  width: ${({ theme }) => theme.breakLarge};

  & p,
  img {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 50%;
  }

  & p {
    margin-left: 25px;
    text-align: left;
  }

  & img {
    margin-right: 25px;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 100%;
    padding: 0 5%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    flex-direction: column;
    height: auto;

    & p,
    img {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    & p {
      margin-top: 50px;
    }
  }
`

// ===============
//      QUERY
// ===============
