import React from "react"
import styled from "styled-components"

const TextIcon = ({ icon, text, alt }) => {

  return (
    <TextIconContainerStyling className="text-icon">
    
      <IconContainerStyling className="icon-container">
        <img src={icon} alt={alt} />
      </IconContainerStyling>
      <h5>{text}</h5>
    </TextIconContainerStyling>
  )
}

export default TextIcon

// ===============
//      STYLES
// ===============

const TextIconContainerStyling = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;

  h5 {
    color: ${({ theme }) => theme.colors.navy};
    font-weight: bold;
    margin-top: 40px;
  }
`

const IconContainerStyling = styled.div`
  width: 116px;
  height: 116px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightgrey};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
`
