import React, { forwardRef } from "react"
import styled from "styled-components"

import { slugify } from "../lib/utils"

const AboutSection = forwardRef(({ sectionRef, navId, aboutData }) => {
  const aboutDescription = aboutData[0]

  const aboutTitle = aboutData[2]

  const imageAboutContent = aboutData[1].localFile.publicURL

  return (
    <AboutSectionContainerStyling ref={sectionRef} id={slugify(navId)}>
      <h2>{aboutTitle}</h2>
      <IntroContainerStyling>
        <p>{aboutDescription}</p>
        <img src={imageAboutContent} alt="Nicolini Nursery School" />
      </IntroContainerStyling>
    </AboutSectionContainerStyling>
  )
})

export default AboutSection

// ===============
//      STYLES
// ===============

const AboutSectionContainerStyling = styled.div`
  margin-top: 100px;
`
const IntroContainerStyling = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ theme }) => theme.breakLarge};
  margin: 50px auto;
  align-items: center;
  height: 350px;

  & p,
  img {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 50%;
  }

  & p {
    margin-right: 25px;
  }

  & img {
    margin-left: 25px;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    flex-direction: column;
    height: auto;

    & p,
    img {
      max-width: 90%;
      width: 90%;
      margin-right: 0;
      margin-left: 0;
    }
    & p {
      margin-bottom: 50px;
    }
  }
`

// ===============
//      QUERY
// ===============
