import React from "react"
import styled from "styled-components"

const StyledButton = ({ text, clicked, link, icon }) => {
  let Icon

  if (icon) {
    Icon = icon
  }
  return (
    <ButtonContainerStyling className="styled-button" onClick={clicked}>
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">
          {text}
          {icon && (
            <StyledIcon>
              <Icon />
            </StyledIcon>
          )}
        </a>
      ) : (
        <span>{text}</span>
      )}
    </ButtonContainerStyling>
  )
}

export default StyledButton

// ===============
//     STYLES
// ===============

const ButtonContainerStyling = styled.button`
  color: ${({ theme }) => theme.colors.navy};
  height: 50px;
  border: 1px solid ${({ theme }) => theme.colors.navy};
  border-radius: 5px;
  background: white;
  min-width: 35%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 25px;

  &:hover {
    background: ${({ theme }) => theme.colors.red};
    color: white;
    border: none;
  }
  a,
  span {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`
const StyledIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`
