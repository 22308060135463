import React, { forwardRef } from "react"
import styled from "styled-components"

import ContactForm from "./contact-form/contact-form"
import { slugify } from "../lib/utils"

const ContactSection = forwardRef(({ sectionRef, navId, contactData }) => {
  const form = contactData.form[0]

  return (
    <ContactContainerStyling ref={sectionRef} id={slugify(navId)}>
      <h2>{contactData.sectionTitle}</h2>
      <h5 className="sub-title">{contactData.sectionSubTitle}</h5>

      <DetailContainerStyling>
        <StyledIframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14358.614142556755!2d28.1155617!3d-25.8808781!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc63dfaefbacfca65!2sNicolini%20Nursery%20School!5e0!3m2!1sen!2sza!4v1649844228468!5m2!1sen!2sza"
          frameBorder="0"
          allowFullScreen
        />
        <div className="form-details">
          <ContactDetailsStyling>
            {contactData.contactDetails.map((contactItem, contactIndex) => {
              return (
                <div className="detail-row" key={contactIndex}>
                  <img
                    src={contactItem.icon.localFile.publicURL}
                    alt={contactItem.title}
                  />
                  <div className="detail-column">
                    <h5>{contactItem.title}</h5>
                    <p>{contactItem.detail}</p>
                  </div>
                </div>
              )
            })}
          </ContactDetailsStyling>
          <ContactForm
            fields={form.field}
            heading={form.formHeading}
            buttonText={form.buttonText}
            formName="contact"
          />
        </div>
      </DetailContainerStyling>
    </ContactContainerStyling>
  )
})

export default ContactSection

// ===============
//      STYLES
// ===============

const ContactContainerStyling = styled.div`
  margin: 100px auto 0 auto;

  .sub-title {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
    text-align: center;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    & > h2,
    h5 {
      width: 90%;
      margin: 0 auto;
    }
  }
`

const DetailContainerStyling = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
  width: 100%;
  margin-top: 50px;

  .form-details {
    padding-right: 100px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-gap: 50px;

    .form-details {
      padding-right: 50px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
    width: 90%;
    margin: 50px auto 0 auto;
    grid-gap: 100px;

    .form-details {
      padding-right: 0;
    }
  }
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin: 30px auto 0;
    height: 400px;
  }
`

const ContactDetailsStyling = styled.div`
  display: flex;
  flex-direction: column;

  .detail-row {
    display: flex;
    flex-direction: row;

    & img {
      height: fit-content;
    }
  }
  .detail-column {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    .detail-row {
      margin: 10px 20px;
    }

    h5 {
      margin: 0;
    }
  }
`
