import React from "react"

const RightblocksIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="90"
    viewBox="0 0 100 90"
  >
    <g data-name="Group 675" transform="translate(-1544.146 -2900)">
      <rect
        width="37"
        height="36"
        fill="#fc4963"
        data-name="Rectangle 883"
        rx="2"
        transform="translate(1607.146 2948)"
      ></rect>
      <circle
        cx="20"
        cy="20"
        r="20"
        fill="#00c0d9"
        data-name="Ellipse 77"
        transform="translate(1544.146 2950)"
      ></circle>
      <path
        fill="#ffcb55"
        d="M16.257 3.1a2 2 0 013.486 0l14.58 25.921A2 2 0 0132.58 32H3.42a2 2 0 01-1.743-2.981z"
        data-name="Polygon 2"
        transform="translate(1566.146 2900)"
      ></path>
    </g>
  </svg>
)

export default RightblocksIcon
