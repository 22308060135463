import React from "react"
import { navigate } from "gatsby-link"
import styled from "styled-components"

const ContactForm = ({ fields, heading, buttonText, formName }) => {
  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <ContactFormContainerStyling>
      {heading && <h4>{heading}</h4>}

      <form
        name={formName}
        data-netlify="true"
        netlify-honeypot="bot-field"
        method="post"
        action="/thanks/"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value={formName} />
        <p hidden>
          <label>
            Don't fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <div className="input-container-styling">
          {fields.map((fieldItem, fieldIndex) => {
            return (
              <input
                key={fieldIndex}
                type={fieldItem.type}
                placeholder={fieldItem.name}
                name={fieldItem.name}
                onChange={handleChange}
                required
              />
            )
          })}
        </div>

        <textarea
          placeholder="Message (optional)"
          name="Message"
          onChange={handleChange}
        ></textarea>

        <button type="submit" name="Submit">
          {buttonText}
        </button>
      </form>
    </ContactFormContainerStyling>
  )
}

export default ContactForm

// ===============
//      STYLES
// ===============

const ContactFormContainerStyling = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;

  h4 {
    margin-bottom: 50px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;

    input {
      width: 100% !important;
      height: 50px;
      background: white !important;
      color: black !important;
    }

    input[type="date"]:empty:before,
    input[type="time"]:empty:before {
      content: attr(placeholder);
      margin-right: 10px;
    }

    input,
    textarea {
      border: 1px solid ${({ theme }) => theme.colors.grey};
      border-radius: 5px;
      padding: 10px;
      outline: none;
    }

    textarea {
      width: 100%;
      height: 100px;
      margin: 20px 0;
      font-family: sans-serif;
    }

    input::placeholder,
    textarea::placeholder {
      color: black;
      line-height: revert;
    }
  }

  button {
    color: ${({ theme }) => theme.colors.navy};
    height: 50px;
    border: 1px solid ${({ theme }) => theme.colors.navy};
    border-radius: 5px;
    background: white;
    min-width: 35%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin-top: 30px;

    &:hover {
      background: ${({ theme }) => theme.colors.red};
      color: white;
      border: none;
    }
  }
  .input-container-styling {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: 1fr;

      input {
        -webkit-appearance: none;
        min-width: 100%;
      }
    }
  }
`
