import React from "react"
import styled from "styled-components"

const PrincipalNoteSection = ({ pnoteData }) => {
  return (
    <AboutSectionContainerStyling>
      <h2>{pnoteData.title}</h2>
      <h5>{pnoteData.subTitle}</h5>
      <IntroContainerStyling>
        <p>{pnoteData.description}</p>
        <img src={pnoteData.image} alt="Nicolini principal" />
      </IntroContainerStyling>
    </AboutSectionContainerStyling>
  )
}

export default PrincipalNoteSection

// ===============
//      STYLES
// ===============

const AboutSectionContainerStyling = styled.div`
  margin-top: 100px;

  h5 {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
    text-align: center;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    & > h2,
    h5 {
      width: 90%;
      margin: 0 auto;
    }
  }
`
const IntroContainerStyling = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ theme }) => theme.breakLarge};
  margin: 50px auto;
  align-items: center;
  height: 500px;

  & p,
  img {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 50%;
  }

  & p {
    margin-right: 25px;
  }

  & img {
    margin-left: 25px;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
    height: auto;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    flex-direction: column;

    & p,
    img {
      max-width: 90%;
      width: 90%;
      margin-right: 0;
      margin-left: 0;
    }
    & p {
      margin-bottom: 50px;
    }
  }
`

// ===============
//      QUERY
// ===============
