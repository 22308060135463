import React from "react"
import styled from "styled-components"

const TeacherCard = ({ image, name, title, alt }) => {
  return (
    <TeacherCardContainerStyling>
      <img src={image} alt={alt} />
      <h4>{name}</h4>
      <p>{title}</p>
    </TeacherCardContainerStyling>
  )
}

export default TeacherCard

// ===============
//      STYLES
// ===============

const TeacherCardContainerStyling = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  img {
    border-radius: 5px;
    width: 100%;
    height: 360px;
    object-fit: cover;
  }

  h4 {
    margin: 20px 0 0 0;
  }

  p {
    margin: 0;
  }
`
