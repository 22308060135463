import React, { useState, useEffect } from "react"

import LandingPageComponent from "../components/landing-page"
import { Cube } from "react-preloaders"

const IndexPage = () => {
  const [isMounted, setMounted] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setMounted(!Boolean)
    }, 1300)
  }, [])

  return (
    <>
        {typeof window === "undefined" ? null : (
          <Cube
            color={"#fc4963"}
            background="white"
            animation="fade"
            time={100}
            customLoading={isMounted}
          />
        )}
        <div style={{ visibility: isMounted ? "hidden" : "visible" }}>
          <LandingPageComponent />
        </div>
    </>
  )
}

export default IndexPage
