import React, { forwardRef } from "react"
import styled from "styled-components"

import StyledButton from "./reusable-components/styled-button"
import { slugify } from "../lib/utils"

import LeftblocksIcon from "../images/icons/leftblocks-icon"
import RightblocksIcon from "../images/icons/rightblocks-icon"

const EnrollmentSection = forwardRef(({ sectionRef, navId, enrollData }) => {
  return (
    <EnrollmentContainerStyling ref={sectionRef} id={slugify(navId)}>
      <EnrollmentInnerStyling>
        <div className="left-icon">
          <LeftblocksIcon />
        </div>
        <div className="right-icon">
          <RightblocksIcon />
        </div>
        <h2>{enrollData.sectionTitle}</h2>
        <h5 className="sub-title">{enrollData.sectionSubTitle}</h5>
        <StyledButton
          text={enrollData.buttonText}
          clicked={null}
          link={enrollData.download.localFile.publicURL}
          icon={false}
        />
      </EnrollmentInnerStyling>
    </EnrollmentContainerStyling>
  )
})

export default EnrollmentSection

// ===============
//      STYLES
// ===============

const EnrollmentContainerStyling = styled.div`
  margin: 100px auto;
  padding: 100px 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.lightgrey};
  position: relative;

  .left-icon {
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translateY(-50%);
  }
  .right-icon {
    position: absolute;
    top: 50%;
    right: 80px;
    transform: translateY(-50%);
  }

  .sub-title {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 400;
    text-align: center;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    .left-icon, .right-icon {
      display: none;
    }
  }
`
const EnrollmentInnerStyling = styled.div`
  width: ${({ theme }) => theme.breakLarge};
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
  }
`
